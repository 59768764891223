<template>
  <div v-if="obj" class="integration-sendgrid">
    <div class="row">
      <div class="col-md-12 mb-4">
        <div class="mt-4 pt-2">
          <span class="pr-2" style="font-weight:600;">OFF</span>
          <b-form-checkbox v-model="obj.syncData" name="check-button" switch inline>
            ON - Sync Data ( Contacts )
          </b-form-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>
        
<script>
export default {
  props: {
    obj: {
      type: Object,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
    }
  },

  computed: {
  },

  mounted() {
  },

  methods: {
  },
}
</script>
        
<style lang="scss" scoped></style>